import React from "react"

import compare_with_others from "../../../assets/Images/Compare_with_others.png"
import know_your_progress from "../../../assets/Images/Know_your_progress.png"
import plan_your_lesson from "../../../assets/Images/Plan_your_lessons.png"
import CTAButton from "./Button"
import HighlightText from "./HighlightText"

const LearningLanguageSection = () => {
  return (
    <div className=" mb-20 mt-16">
      <div className="flex flex-col items-center gap-5">
        <div className="text-center text-4xl font-semibold">
          Your Swiss Knife for
          <HighlightText text={" learning any language"} />
        </div>

        <div className="mx-auto w-[70%] text-center text-base font-medium text-richblack-600">
          Using spin making learning multiple languages easy. with 20+ languages
          realistic voice-over, progress tracking, custom schedule and more.
        </div>

        <div className="mt-5 flex flex-col items-center justify-center lg:flex-row">
          <img
            src={know_your_progress}
            alt="KNowYourProgressImage"
            className="object-contain lg:-mr-32  lg:mt-0 "
          />
          <img
            src={compare_with_others}
            alt="KNowYourProgressImage"
            className="-mt-10 object-contain lg:mt-0"
          />
          <img
            src={plan_your_lesson}
            alt="KNowYourProgressImage"
            className="-mt-14 object-contain lg:-ml-36 lg:mt-0"
          />
        </div>

        <div className="w-fit">
          <CTAButton active={true} linkto={"/signup"}>
            <div>Learn more</div>
          </CTAButton>
        </div>
      </div>
    </div>
  )
}

export default LearningLanguageSection
